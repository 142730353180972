import React, { Component } from "react";
import cons from "../cons.js";
import TronLinkGuide from "./tronlink.js";

const TronWeb = require('tronweb')

const BigNumber = require('bignumber.js');

function delay(s) { return new Promise(res => setTimeout(res, s * 1000)); }

export default class CiroTrx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tronWeb: {
        loggedIn: false,
        installed: false
      },
      contrato: {
        ciro_trx: null
      },
      wallet: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
      elementSelect: <option value={0}>Loading...</option>,
      tokenSelected: "Loading...",
      disponible: 0,
      recivedAmount: 0,
      idMoneda: 0,
      modalTitle: "Title",
      modalBody: "body",

    };

    this.conectar = this.conectar.bind(this);

    this.compra = this.compra.bind(this);
    this.estado = this.estado.bind(this);
    this.opciones = this.opciones.bind(this);

    this.rentEnergy = this.rentEnergy.bind(this);
    this.recursos = this.recursos.bind(this);
    this.calcularRecurso = this.calcularRecurso.bind(this);

  }

  async componentDidMount() {
    document.title = "Dapp | CiroTrx"

    await this.conectar();

    window.addEventListener('message', (e) => {

      if (e.data.message && (e.data.message.action === "accountsChanged" || e.data.message.action === "setAccount")) {
        if (e.data.message.data.address) {
          this.conectar();
        }
      }
    })
    setTimeout(async () => {

      await this.conectar();
      if (this.state.tronWeb.loggedIn) {
        this.estado();

      }

    }, 3 * 1000);

    setInterval(() => {

      this.conectar();
      if (this.state.tronWeb.loggedIn) {
        this.estado();
      }
      new BigNumber(0).toNumber()
    }, 180 * 1000);

  }

  async conectar() {

    var { tronWeb, wallet, contrato } = this.state;
    var conexion = 0;

    if (typeof window.tronWeb !== 'undefined' && typeof window.tronLink !== 'undefined') {

      tronWeb['installed'] = true;


      if (window.tronWeb.ready || window.tronLink.ready) {

        try {
          conexion = (await window.tronLink.request({ method: 'tron_requestAccounts' })).code;
        } catch (e) {
          conexion = 0
        }

        if (conexion === 200) {
          tronWeb['loggedIn'] = true;
          try {
            wallet = window.tronLink.tronWeb.defaultAddress.base58

          } catch (error) {

          }

        } else {
          tronWeb['loggedIn'] = false;
          wallet = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";

        }

        tronWeb['web3'] = window.tronWeb;

        //window.tronWeb.setHeader({"TRON-PRO-API-KEY": 'your api key'});

        if (this.state.contrato.USDT == null) {

          const KEYS = (process.env.REACT_APP_APIKY).split(",")

          let ranNum = Math.floor((Math.random() * KEYS.length));

          const tronWeb = new TronWeb({
            fullHost: 'https://api.trongrid.io',
            headers: { "TRON-PRO-API-KEY": KEYS[ranNum] }
          })

          tronWeb.setAddress(wallet)

          //window.tronWeb.setHeader({ "TRON-PRO-API-KEY": process.env.REACT_APP_APIKY })

          contrato = {};

          if (cons.SC !== "") {
            contrato.ciro_trx = await tronWeb.contract().at(cons.SC);
          }

          this.setState({
            contrato: contrato

          });

        }


        this.setState({
          accountAddress: wallet,
          tronWeb: tronWeb,

        });
      } else {

        this.setState({
          tronWeb: tronWeb,

        });

      }


    } else {

      tronWeb['installed'] = false;
      tronWeb['loggedIn'] = false;

      this.setState({
        tronWeb: tronWeb

      });
    }
  }

  async estado() {

    var accountAddress = this.state.accountAddress;

    var balance = await window.tronWeb.trx.getBalance();
    balance = new BigNumber(balance).shiftedBy(-6).toNumber();

    if (balance <= 0) {
      this.setState({
        modalTitle: "Alert!",
        modalBody: "Need some TRX to pay bandwidth"
      })

      window.$("#exampleModal").modal("show");
    }

    var tokenList = await this.state.contrato.ciro_trx.tokenList().call();

    var idMoneda = this.state.idMoneda

    var elementSelect = [];

    var tokenContratos = [];

    var fees = [];

    for (let index = 0; index < tokenList[0].length; index++) {

      let contract_token = await window.tronWeb.contract().at(tokenList[0][index]);

      if (contract_token.implementation && true) {//
        var address_imp = await contract_token.implementation().call()

        if (typeof address_imp !== "string") { address_imp = address_imp[0] }
        if (address_imp !== "410000000000000000000000000000000000000000") {
          let contract_imp = await window.tronWeb.contract().at(address_imp)
          contract_token = await window.tronWeb.contract(contract_imp.abi, tokenList[0][index]);
        }

      }

      tokenContratos.push(contract_token)

      let symbol = await contract_token.symbol().call();
      let name = await contract_token.name().call();
      let decimals = await contract_token.decimals().call();
      if (decimals._hex) {
        decimals = parseInt(decimals._hex)
      }

      //console.log("index: " + index); console.log(decimals);
      let fee = 0;
      if (tokenList[2][index]) {
        fee = tokenList[1][index];
        if (fee._hex) {
          fee = fee._hex
        }

        fee = new BigNumber(fee).shiftedBy(-decimals)
        fees.push(fee.toNumber())

        fee = fee.toString(10) + " " + symbol
      } else {
        fee = (tokenList[1][index] / tokenList[3][index]) * 100
        fees.push(fee)

        fee = fee + " %"
      }

      elementSelect[index] = <option key={"objets" + index} value={index}>{symbol} ({name}) - Fee {fee} </option>

    }

    var decimals2 = await tokenContratos[idMoneda].decimals().call()
    var disponible = await tokenContratos[idMoneda].balanceOf(this.state.accountAddress).call()
    if (disponible._hex) {
      disponible = disponible._hex
    }
    disponible = new BigNumber(disponible).shiftedBy(-decimals2)

    this.setState({
      wallet: accountAddress,
      elementSelect: elementSelect,
      tokenSelected: await tokenContratos[idMoneda].symbol().call(),
      tokenContratos: tokenContratos,
      fees: fees,
      disponible: disponible.toNumber()
    });

  }

  async opciones() {

    var tokenContratos = this.state.tokenContratos;

    var fees = this.state.fees;

    var idMoneda = document.getElementById("token").value;

    this.setState({
      idMoneda: idMoneda
    })

    var disponible = await tokenContratos[idMoneda].balanceOf(this.state.accountAddress).call()
    if (disponible._hex) {
      disponible = disponible._hex
    }

    var decimals2 = await tokenContratos[idMoneda].decimals().call()
    if (decimals2._hex) {
      decimals2 = parseInt(decimals2._hex)
    }
    disponible = new BigNumber(disponible).shiftedBy(-decimals2)

    var amount = document.getElementById("amount").value;
    amount = amount.replace(",", ".")
    amount = new BigNumber(document.getElementById("amount").value);


    if (amount.toNumber() > disponible.toNumber()) {
      amount = disponible
      document.getElementById("amount").value = disponible.toString(10)
    }

    if (amount > fees[idMoneda]) {
      amount = amount.minus(fees[idMoneda])

    } else {
      amount = 0
    }

    this.setState({
      tokenSelected: await tokenContratos[idMoneda].symbol().call(),
      disponible: disponible.toString(10),
      recivedAmount: amount.toString(10),
    });

  }


  async recursos() {
    var url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + "available"
    var consulta = await fetch(url).then((r) => r.json())

    var band = consulta["BANDWIDTH_-_Rental_duration_less_or_equal_to_3_days"]
    var energi = consulta["ENERGY_-_Rental_duration_less_or_equal_to_3_days"]

    this.setState({
      available_bandwidth: band,
      available_energy: energi,
      total_bandwidth_pool: consulta.total_bandwidth_pool,
      total_energy_pool: consulta.total_energy_pool
    });
  }

  async calcularRecurso(amount) {

    await this.recursos();
    var url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + "prices"

    var body = { "resource": "ENERGY", "amount": amount, "duration": "1h" }

    this.setState({
      precio: "Calculating..."
    })

    var consulta2 = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((r) => r.json())

    console.log(consulta2)

    var precio = consulta2.price * 1
    precio = parseInt(precio * 10 ** 6) / 10 ** 6

    return precio

  }

  async rentEnergy(cantidad) {

    var precio = await this.calcularRecurso(cantidad, "1h")
    var recurso = "energy"
    var time = "1h"
    var wallet_orden = this.state.accountAddress


    const imgLoading = <img src="assets/images/cargando.gif" height="20px" alt="loading..." />

    if (await this.calcularRecurso(cantidad, "1h") > await window.tronWeb.trx.getUnconfirmedBalance() / 10 ** 6) {

      this.setState({
        modalTitle: <>Insufficient balance to rent resources for the first time</>,
        modalBody: <>You need at least {precio + 1} trx to complete the energy rental this first time with this token and then you can use it only having enough bandwidth for the transaction</>
      })

      window.$("#exampleModal").modal("show");
      return;
    }

    this.setState({
      modalTitle: <>Confirm transaction {imgLoading}</>,
      modalBody: <>Please confirm the transaction from your wallet </>
    })

    window.$("#exampleModal").modal("show");

    var hash = await window.tronWeb.trx.sendTransaction(process.env.REACT_APP_WALLET_API, window.tronWeb.toSun(precio))
      .catch((e) => {
        console.log(e)
        return ["e", e];
      })

    if (hash[0] === "e") {
      this.setState({
        modalTitle: "Transaction failed",
        modalBody: <>{hash[1]}
          <br /><br />
          <button type="button" className="btn btn-danger" onClick={() => { window.$("#exampleModal").modal("hide") }}>Close</button>
        </>
      })

      window.$("#exampleModal").modal("show");
      return;
    }

    this.setState({
      modalTitle: <>Waiting for the blockchain {imgLoading}</>,
      modalBody: "We are waiting for the blockchain to process and confirm your transfer. This can take from 3 seconds to 1 minute"
    })

    window.$("#exampleModal").modal("show");

    await delay(3);

    var envio = hash.transaction.raw_data.contract[0].parameter.value

    this.setState({
      modalTitle: <>we are verifying {imgLoading}</>,
      modalBody: "We are verifying that the amounts and the address to which the funds were sent are the correct address, please do not close or exit the website as this may affect this process."
    })

    window.$("#exampleModal").modal("show");

    if (hash.result && envio.amount + "" === window.tronWeb.toSun(precio) && window.tronWeb.address.fromHex(envio.to_address) === process.env.REACT_APP_WALLET_API) {

      hash = await window.tronWeb.trx.getTransaction(hash.txid);

      if (hash.ret[0].contractRet === "SUCCESS") {

        this.setState({
          modalTitle: <>we are allocating your {recurso} {imgLoading}</>,
          modalBody: "Please do not close or leave the page as this will cause an error in the " + recurso + " allocation."
        })

        window.$("#exampleModal").modal("show");

        var url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + recurso

        var body = {
          "id_api": process.env.REACT_APP_USER_ID,
          "wallet": wallet_orden,
          "amount": cantidad,
          "time": time,
          "user_id": "fromCiro"
        }

        var consulta2 = await fetch(url, {
          method: "POST",
          headers: {
            'token-api': process.env.REACT_APP_TOKEN,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }).then((r) => r.json())

        console.log(consulta2)

        if (consulta2.response === 1) {

          this.setState({
            modalTitle: "Completed successfully",
            modalBody: <><p>Energy rental completed successfully. </p><button type="button" data-bs-dismiss="modal" className="btn btn-success">continue</button></>
          })
          window.$("#exampleModal").modal("show");

          return true;

        } else {

          var msg = consulta2.msg || "";

          this.setState({
            modalTitle: "Contact support",
            modalBody: "Please contact support for: " + hash.txID + " | " + msg
          })
          window.$("#exampleModal").modal("show");

        }

      } else {
        this.setState({
          modalTitle: "Contact support",
          modalBody: "Please contact support for: Error SUC-808831"
        })
        window.$("#exampleModal").modal("show");
      }


    } else {
      this.setState({
        modalTitle: "Contact support",
        modalBody: "Please contact support for: Error NN-0001"
      })
      window.$("#exampleModal").modal("show");
    }

  }

  async compra() {

    const imgLoading = <img src="assets/images/cargando.gif" height="20px" alt="loading..." />

    var idMoneda = document.getElementById("token").value;

    const minCompra = 10 ** 6

    var amount = document.getElementById("amount").value;
    var wallet = document.getElementById("wallet").value;

    var tokenContratos = this.state.tokenContratos;
    const contract_token = tokenContratos[idMoneda];

    var balance = parseInt((await contract_token.balanceOf(this.state.accountAddress).call())._hex)
    var aproved = await contract_token.allowance(this.state.accountAddress, this.state.contrato.ciro_trx.address).call()

    amount = amount.replace(",", ".")
    amount = parseFloat(amount);
    var decimal = await contract_token.decimals().call()
    if (decimal._hex) {
      decimal = parseInt(decimal._hex)
    }
    amount = new BigNumber(amount).shiftedBy(decimal)

    if (aproved.remaining) {
      aproved = aproved.remaining
    }

    aproved = parseInt(aproved._hex)

    if (amount.toNumber() > aproved) {

      this.setState({
        modalTitle: "Token Alert",
        modalBody: <p>It is the first time that you are going to make a shipment with this Token, this first transaction will be more expensive because it is an external contract, we recommend that you rent energy to continue the process successfully
          <br></br><button className="btn btn-success" onClick={async () => {
            if (await this.rentEnergy(60000)) {
              await contract_token.approve(this.state.contrato.ciro_trx.address, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send()
              this.compra();
            }
          }}>Continue renting energy</button><br></br>
        </p>
      })

      window.$("#exampleModal").modal("show");

      return;

    }

    if (balance >= amount.toNumber()) {
      if (amount.toNumber() >= minCompra) {

        if (!window.tronWeb.isAddress(wallet)) {
          this.setState({
            modalTitle: "Input error",
            modalBody: "Invalid Wallet"
          })

          window.$("#exampleModal").modal("show");
          return;
        }

        this.setState({
          modalTitle: <>Confirm transaction {imgLoading}</>,
          modalBody: <>Please confirm the transaction from your wallet </>
        })

        window.$("#exampleModal").modal("show");

        var result = await this.state.contrato.ciro_trx.transfer(wallet, amount.toString(10), idMoneda).send();

        this.setState({
          modalTitle: <>Waiting for the blockchain {imgLoading}</>,
          modalBody: "We are waiting for the blockchain to process and confirm your transfer. This can take from 3 seconds to 1 minute"
        })

        window.$("#exampleModal").modal("show");
        await delay(3)
        result = await window.tronWeb.trx.getTransaction(result);

        if (result.ret[0].contractRet === "SUCCESS") {
          this.setState({
            modalTitle: "Transacction successful",
            modalBody: "Your send is done!"
          })

          window.$("#exampleModal").modal("show");
        } else {
          this.setState({
            modalTitle: "Transacction failed",
            modalBody: "Apparently there was an error in the transaction, please contact support. hash: " + result.ret[0].txId
          })

          window.$("#exampleModal").modal("show");
        }
        document.getElementById("amount").value = "";


      } else {

        this.setState({
          modalTitle: "input error",
          modalBody: "Please enter an amount greater amount"
        })

        window.$("#exampleModal").modal("show");

      }

    } else {

      this.setState({
        modalTitle: "input error",
        modalBody: "Por favor, inténtelo de nuevo más tarde"
      })

      window.$("#exampleModal").modal("show");

      document.getElementById("amount").value = "";

    }

    this.estado();

  };


  render() {

    if (!this.state.tronWeb.loggedIn || !this.state.tronWeb.installed) return (

      <div className="container" style={{ marginTop: "50px" }}>
        <TronLinkGuide installed={this.state.tronWeb.installed} />
      </div>
    );

    return (

      <>

        <div className="contact-form-area style-two pt-100 pb-100">

          <div className="container">
            <div className="row">
              <div className="dreamit-section-title text-center upper1 pb-70">
                <h4>CiroTrx</h4>
                <h1 className="section-title">Stablecoin on TRON made simple</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="contact-form-thumb wow fadeInRight" data-wow-delay=".4s">
                  <img src="assets/images/resource/CIROTRX.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row">
                  <div className="contact-form-box wow fadeInLeft" data-wow-delay=".4s">
                    <div className="contact-form-title">
                      <h3>CiroTrx</h3>
                    </div>
                    <form id="dreamit-form">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white">Recipient wallet</p>
                          <div className="from-box">
                            <input type="text" id="wallet" placeholder="Paste or enter the account address" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white">Choose a stablecoin</p>

                          <div className="from-box">
                            <div className="row">
                              <div className="col-2">
                                <img className="" style={{ margin: "7px 20px", width: "35px", height: "35px" }} src={"assets/images/" + this.state.tokenSelected + "-logo.png"} alt={this.state.tokenSelected + "-logo"} />
                              </div>
                              <div className="col-10">
                                <select name="select" id="token" onChange={() => { this.opciones() }} style={{ padding: "6px 20px", borderRadius: "30px", width: "100%", height: "54px", marginBottom: "20px", backgroundColor: "transparent", color: "#8e8e8e", border: "1px solid #353D51" }}>
                                  {this.state.elementSelect}
                                </select>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white">Amount</p>
                          <div className="from-box">
                            <input type="number" id="amount" onChange={() => { this.opciones() }} placeholder="0" min={0} />
                          </div>
                          <p className="" style={{ fontSize: "0.9rem", color: "#808080" }}>Available: {this.state.disponible} {this.state.tokenSelected}</p>
                        </div>
                        <div className="col-lg-12 col-sm-12 btn-group" role="group" >
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = ((this.state.disponible) * 0.25).toPrecision(6); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>25%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = ((this.state.disponible) * 0.50).toPrecision(6); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>50%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = ((this.state.disponible) * 0.75).toPrecision(6); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>75%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = ((this.state.disponible) * 1).toPrecision(6); this.opciones() }} style={{ borderRadius: "10px", backgroundColor: "#1DD1A1" }}>100%</button>

                        </div>
                      </div>
                      <div className="from-box">
                        <p className="text-white text-center">Received amount: {this.state.recivedAmount} {this.state.tokenSelected}</p>
                        <button type="button" style={{ width: "100%" }} onClick={async () => { await this.opciones(); this.compra() }}>Send</button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                See last alert message
              </button>
            </div>
          </div>

        </div>



        <div className="modal" id="exampleModal" style={{ zIndex: 999 }} aria-labelledby="exampleModalLabel" aria-hidden="true" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark" id="exampleModalLabel">{this.state.modalTitle}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {this.state.modalBody}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

      </>

    );
  }
}
